import React, { useState } from "react"
import { mainMenuItems } from "../constants/links"
import styled from "styled-components"
import { Link } from "gatsby"
import navbar_ripped from "../images/navbar-ripped.svg"
import dropdown_ripped from "../images/dropdown-ripped.svg"

const Navbar = ({ Logo }) => {
  const [isOpen, setNav] = useState(false)

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  const closeNav = () => setNav(false)
  return (
    <HeaderStyles>
      <LogoStyles>
        <Link
          onClick={closeNav}
          swipe
          direction="left"
          entryOffset={100}
          to="/"
        >
          <img src={Logo} alt="Srinon Thai Street Food Logo" />
        </Link>
      </LogoStyles>
      <DestinationStyles>
        <div className="masthead flex-container">
          <button
            className={isOpen ? "toggle-btn toggle-btn-active" : "toggle-btn"}
            type="button"
            onClick={toggleNav}
            aria-label="Menu Button"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <ul className={isOpen ? "nav-links show-nav" : "nav-links"}>
            {mainMenuItems.map((item, index) => {
              return (
                <li key={index}>
                  <Link onClick={closeNav} to={`${item.path}${item.text}`}>
                    {item.text}
                  </Link>
                </li>
              )
            })}
            {/* <li>
              <Link onClick={closeNav} to={`/gallery`}>
                gallery
              </Link>
            </li> */}
          </ul>
        </div>
      </DestinationStyles>
    </HeaderStyles>
  )
}

export const HeaderStyles = styled.header`
  z-index: 10;
  position: fixed;
  width: 100%;
  display: flex;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  box-sizing: border-box;
  background-image: url(${navbar_ripped});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: margin-box;
`

export const LogoStyles = styled.div`
  width: 100%;
  z-index: 3;

  a {
    img {
      width: 100px;
      @media (max-width: 992px) {
        width: 80px;
      }
    }
  }
}
`

export const DestinationStyles = styled.nav`
  .masthead {
    width: 100%;
    justify-content: space-between;
    position: relative;
    flex-direction: row;
  }

  .nav-links {
    z-index: 2;
    margin: 0;
    font-family: "Asul";
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: fixed;
    background-image: url(${dropdown_ripped});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: translateY(-100%);
    transition: all 0.3s ease-in 0s;
    list-style: none;

    li {
      margin-left: 0;
      list-style: none;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0.55rem;

      a {
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        transition: all 0.3s ease 0s;

        &.active {
          color: color(display-p3 0.996078 0.756863 0.168627 / 1);
        }
      }
      &:hover {
        cursor: pointer;
        a {
          color: color(display-p3 0.996078 0.756863 0.168627 / 1);
        }
      }
    }

    &.show-nav {
      transform: translateX(0%);
    }
  }

  .toggle-btn {
    z-index: 3;
    width: 60px;
    height: 60px;
    padding: 5px;
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
      span {
        background-color: color(display-p3 0.996078 0.756863 0.168627 / 1);
      }
    }

    span {
      margin: auto;
      margin-left: 6px;
      position: relative;
      float: left;
      clear: both;
      display: block;
      width: 30px;
      height: 3px;
      background-color: #fff;
      transition: 0.2s ease-in;

      &:nth-child(1) {
        transform: translateY(-5px);
      }

      &:nth-child(3) {
        transform: translateY(5px);
        margin-bottom: 6px;
      }
    }

    &.toggle-btn-active {
      span {
        &:nth-child(1) {
          transform: translateY(3px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }

        &:nth-child(3) {
          transform: translateY(-3px) rotate(-45deg);
        }
      }
    }
  }

  @media (min-width: 992px) {
    .masthead {
      flex-direction: row;
      justify-content: center;
    }

    .toggle-btn {
      display: none;
    }

    .nav-links {
      background: transparent;
      flex-direction: row;
      margin-left: auto;
      position: relative;
      transform: translateX(0);
      transition: none 0.2s ease 0.2s;

      li {
        margin-left: 0;
      }
    }
  }
`

export default Navbar
