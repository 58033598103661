export const mainMenuItems = [
  {
    path: "/#",
    text: "menus",
  },
  {
    path: "/#",
    text: "takeaway",
  },
  {
    path: "/#",
    text: "about",
  },
  {
    path: "/#",
    text: "contact",
  },
]
