import React from "react"
import { FaFacebook, FaInstagram } from "react-icons/fa"

export const socialMenuItems = [
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/srinonthaistreetfood",
    name: "Facebook",
  },
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/srinonthai/",
    name: "Instagram",
  },
]
