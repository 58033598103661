import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { socialMenuItems } from "../constants/social-icons"
import styled from "styled-components"
import PropTypes from "prop-types"

const Footer = ({ Logo }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            authorSite
          }
        }
      }
    `
  )

  const footerData = data.site.siteMetadata

  return (
    <FooterStyles>
      <div className="flex">
        <SocialMenuContainer>
          <SocialMenu>
            {socialMenuItems.map((item, index) => (
              <SocialLink
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.icon}
                <span className="sr-only">{item.name}</span>
              </SocialLink>
            ))}
          </SocialMenu>
        </SocialMenuContainer>
        <div className="copy-cont">
          <ul className="copy">
            <li>&copy; {new Date().getFullYear()}</li>

            {/* if there is an author stated in the config, render this */}
            {footerData.author && (
              <li>
                All rights reserved {footerData.author} | Website by{" "}
                <a
                  href="https://bence.codes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bence Gadanyi
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </FooterStyles>
  )
}

Footer.propTypes = {
  Logo: PropTypes.string,
}

const SocialMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margint-top: 1rem;
    margin-bottom: 0;
  }
`

const SocialMenu = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`

const SocialLink = styled.a`
  color: #ffc12a;
  font-size: 1.5rem;
  margin-right: 1rem;
  transition: color 0.3s;

  &:hover {
    color: #ffb300;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    padding-top: 1rem;
    margin-bottom: 0;
  }
`

const FooterStyles = styled.footer`
  padding: calc(var(--spacing) * 2);
  background-color: color(display-p3 0.070588 0.078431 0.070588 / 1.000000);
  font-weight: 300;
  font-style: normal;
  color: color(display-p3 0.996078 0.756863 0.168627 / 1.000000);
  position: relative; 
  left: 0 ; 
  right: 0; 
  bottom: 0;
  width: 100%;

  .flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    justify-content: center;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;
    }
  }

  li {
    color:
    text-transform: uppercase;
    list-style: none;
    padding-left: 0;
  }

  a {
    color: #fff;

    &:hover {
      color: color(display-p3 0.964706 0.729412 0.078431 / 1.000000);
    }
  }

  .copy-cont {
    width: 100%;

    .copy {
      justify-content: center;
      display: flex;
      padding: 0;
      margin-top: 0;
      text-transform: uppercase;
      margin-bottom: 0;
      

      a {
        color: color(display-p3 0.996078 0.756863 0.168627 / 1.000000);
      }

      li {
        margin-right: var(--spacing);
      }

      @media (min-width: 768px) {
        justify-content: center;

        li {
          margin: 0 calc(var(--spacing) / 2);
        }
      }

      @media (max-width: 768px){
        font-size: 0.8rem;
      }
    }
  }
`

export default Footer
